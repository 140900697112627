@media (max-width: 900px) {
    .footer{    
        border-top: 1px solid #f5f5f5;
        color: gray;
        position: relative;
        bottom: 0;
        width: 100%;
        text-align: center;
        display: block;

    }
    .ciopaer{
        display: none;
    }
 }
 @media (min-width: 900px) {
    .footer{     
        border-top: 1px solid #f5f5f5;
        color: gray;
        position: relative;
        bottom: 0;
        width: 100%;
        text-align: center;
        display: block;
    }
    
 }